import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../components/layout";

import "../styles/basic-page.scss";

const PrivacyPage = ({ data }) => {
  const text = data.sanitySiteSettings._rawPrivacy;

  return (
    <Layout pageTitle="Privacy">
      <div className="text">
        <BlockContent blocks={text} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanitySiteSettings(_id: { eq: "single-siteSettings" }) {
      _rawPrivacy
    }
  }
`;

export default PrivacyPage;
